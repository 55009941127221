import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../utils/style';
import Img from 'gatsby-image';
import Bell from '../../icons/svg/Bell';

const Wrapper = styled('div')`
  .h3 {
    margin: 2rem 0;
    min-height: 50px;
  }
  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    list-style: none;
  }
  li {
    flex-basis: 32%;
    position: relative;
  }

  .item-link {
    position: relative;
    display: block;
    overflow: hidden;

    .image-container {
      position: relative;
      border-radius: 10px;
    }
    .button-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 20px;
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      border-radius: 10px;
    }

    .gatsby-image-wrapper {
      border-radius: 10px;
    }

    .text-part-2 {
      padding: 7px 0 54px;
    }

    .h5 {
      display: block;
      font-size: 1rem;
      line-height: 24px;
      max-height: 24px;
      margin: 24px 0;
      overflow: hidden;
    }

    .button {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -54px;
      margin-top: -25px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    .excerpt {
      display: block;
      font-size: 14px;
      line-height: 20px;
      max-height: 99px;
      overflow: hidden;
      opacity: 0.8;
    }

    &:hover,
    &:active {
      text-decoration: none;
      color: ${color.white};
      border: none;
      .button-container {
        background: rgba(0, 0, 0, 0.8);
      }
      .button {
        visibility: visible;
        opacity: 1;
      }
    }
    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    li {
      flex-basis: 48%;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 20px;
    .h3 {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 0.7rem;
    }
    li {
      flex-basis: 100%;
      margin-bottom: 20px;
    }
    .item-link {
      .h5 {
        margin: 0 0 10px;
      }
    }
  }
`;

export default ({ limit }) => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPost(
          filter: {
            categories: { elemMatch: { wordpress_id: { in: [19, 12, 13] } } }
            status: { eq: "publish" }
          }
          sort: { fields: date, order: DESC }
          skip: 0
          limit: 6
        ) {
          edges {
            node {
              slug
              title
              excerpt
              featured_media {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        placeholder: file(relativePath: { eq: "placeholder-colored.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <h3 className="h3 with-icon">
          <Bell />
          <span>ข่าวและอัปเดตล่าสุดเกี่ยวกับ Gclub</span>
        </h3>
        <ul>
          {data.allWordpressPost.edges.map(item => (
            <li key={item.node.label + item.node.slug}>
              <Link className="item-link" to={'/' + item.node.slug + '/'}>
                <div className="image-container">
                  {item.node.featured_media &&
                  item.node.featured_media.localFile &&
                  item.node.featured_media.localFile.childImageSharp &&
                  item.node.featured_media.alt_text ? (
                    <Img
                      fluid={
                        item.node.featured_media.localFile.childImageSharp.fluid
                      }
                      alt={item.node.featured_media.alt_text}
                    />
                  ) : (
                    <Img
                      fluid={data.placeholder.childImageSharp.fluid}
                      alt={item.node.label}
                    />
                  )}
                  <div className="button-container">
                    <span className="button gradient-button">คลิกเลย</span>
                  </div>
                </div>
                <div className="text-part-2">
                  <span className="h5">{item.node.title}</span>
                  <div
                    className="excerpt"
                    dangerouslySetInnerHTML={{ __html: item.node.excerpt }}
                  />
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </Wrapper>
    )}
  />
);
