import React from 'react';
const Heart = ({ width = '51', className = '', height = '47', viewBox='0 0 51 47' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="svg-heart-b" d="M19.912 35.831c-.39 0-.765-.14-1.06-.392a192.503 192.503 0 01-9.306-8.542C5.092 22.514.32 17.357.116 11.717c-.118-3.294.888-6.143 2.91-8.238C5.103 1.327 8.072.139 11.39.139h.016c3.492 0 6.716 2.386 8.53 5.074C21.97 2.313 25.413.25 28.604.142c3.19-.109 6.198 1.006 8.264 3.06 2.066 2.053 3.13 4.973 3.045 8.379-.144 5.081-5.28 10.88-9.74 15.366a1.043 1.043 0 01-1.48-1.472c3.861-3.883 9.008-9.554 9.133-13.955.081-2.813-.76-5.178-2.43-6.839-1.669-1.661-4.106-2.544-6.721-2.454-2.934.1-6.155 2.295-7.661 5.217a1.196 1.196 0 01-1.104.666 1.223 1.223 0 01-1.094-.687c-1.46-2.965-4.64-5.202-7.413-5.202h-.014c-2.745 0-5.18.957-6.86 2.698-1.622 1.67-2.425 3.999-2.327 6.714.177 4.875 4.639 9.662 8.809 13.774a192.299 192.299 0 008.903 8.189 139.23 139.23 0 005.238-4.69 1.044 1.044 0 111.427 1.524 143.215 143.215 0 01-5.616 5.009 1.62 1.62 0 01-1.051.392z"/>
      <filter id="svg-heart-a" width="150.2%" height="156%" x="-25.1%" y="-28%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter2"/>
        <feOffset in="shadowSpreadOuter2" result="shadowOffsetOuter2"/>
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
        </feMerge>
      </filter>
      <path id="svg-heart-d" d="M5.693 13.023a1.043 1.043 0 01-1.043-1.006C4.53 8.73 6.202 6.13 9.12 5.067a1.043 1.043 0 01.716 1.96c-2.056.751-3.187 2.542-3.101 4.912a1.043 1.043 0 01-1.004 1.082l-.038.002z"/>
      <filter id="svg-heart-c" width="441.7%" height="349.6%" x="-170.8%" y="-124.8%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter2"/>
        <feOffset in="shadowSpreadOuter2" result="shadowOffsetOuter2"/>
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(5 6)">
        <use fill="#000" filter="url(#svg-heart-a)" href="#svg-heart-b"/>
        <use fill="#FFCDCD" href="#svg-heart-b"/>
      </g>
      <g transform="translate(5 6)">
        <use fill="#000" filter="url(#svg-heart-c)" href="#svg-heart-d"/>
        <use fill="#FFCDCD" href="#svg-heart-d"/>
      </g>
    </g>
  </svg>;

  export default Heart;