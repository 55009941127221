import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import LazyLoad from 'react-lazyload';
import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/style';

const ImgContainer = styled('div')`
  width: 204px;
  position: relative;
  z-index: -1;
  @media (max-width: ${breakpoints.xs}) {
    width: 126px;
  }
`;

const ChooseGameImg = () => {
  return(
  <StaticQuery
    query=
      {graphql`
        query {
          cards: file(relativePath: { eq: "cards.png" }) {
            childImageSharp {
              fluid(maxWidth: 204) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
    render={data => (
      <LazyLoad>
        <ImgContainer className="choose-game-img">
          <Img alt="Choose your way to play" fluid={data.cards.childImageSharp.fluid}/>
        </ImgContainer>
      </LazyLoad>
    )}
  />
)};

export default ChooseGameImg;