import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/shared/layout-home';
import SEO from '../components/SEO/SEO';
import styled from '@emotion/styled';
import SlickSlide from '../components/shared/SlickSlideNew';
import { breakpoints, ContentStyles, color } from '../utils/style';
import Steps from '../components/Content/Home/StepsNew';
import PlayForFree from '../components/Content/Home/PlayForFree';
import Demo10Games from '../components/Content/Home/SlotGames';
import RecentPosts from '../components/Content/RecentPosts';
import PopularGames from '../components/Content/Home/PopularGames.js';
import WithdrawCommentsStaticLoadmore from '../components/Content/Withdraw/Comments/CommentsStaticLoadmore';
import LazyLoad from 'react-lazyload';
import GameProviders from '../components/Content/Home/GameProviders';
import withLocation from '../components/shared/withLocation';
import Parallax from '../components/shared/Parallax';
import MobileGameProvider from '../components/Content/Home/MobileGameProvider';
import ChooseGameImg from '../components/Content/Home/ChooseGameImg';


const ContentWrapper = styled('div')`
  text-align: center;
  .show-more {
    .more-span {display: inline;}
    .less-span {display: none;}
  }
  .show-less {
    .more-span {display: none;}
    .less-span {display: inline;}
  }
  .home-text-content {
    max-height: 220px;
    overflow: hidden;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    margin-bottom: 20px;
    
  }
  .full-height {
    max-height: 50000px;
  }
  .home-image-flex {
    svg { width: 150px; }
    .col-left {
      flex: 1;
    }
    .col-right {
      flex: 0;
      padding-left: 20px;
    }
  }
  
  ${ContentStyles};
  @media (max-width: ${breakpoints.sm}) {
    .h3 {
      margin-top: 0;
    }
    .home-text-content {
      max-height: 440px;
      //>h2,>h3,>p,>ul{
      //  display:none;
      //}
    }
    .full-height {
      max-height:100%;
      //>h2,>h3,>p,>ul{
      //  display:block;
      //}
    }
    .home-image-flex {
      display: block;
      .col-right {
        text-align: center;
        padding: 10px;
      }
    }
  }
`;
const CardsWrapper = styled('div')`
  width: 100%;
  max-width: 1244px;
  margin: 0 auto;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    margin-top: -65px;
    text-shadow: 0 3px 3px #411d17, 0 0 3px rgba(255, 192, 0, 0.89),
      0 0 0 #ffab00;
    -webkit-text-stroke: 0.7px #ff9e00;
    font-family: Kanit, sans-serif;
    font-size: 43px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color.yellow};
  }
  @media (max-width: ${breakpoints.xs}) {
    span {
      font-size: 22.8px;
      max-width: 280px;
      text-align: center;
      margin-top: -48px; 
    }
  }
`;

const WrapperDesktop = styled('div')`
  max-width: 1244px;
  margin: 0 auto;
  padding: 5px 10px;
`;

const WrapperPadded = styled('div')`
  max-width: 1244px;
  margin: 0 auto;
  padding: 5px 10px;
  @media (max-width: ${breakpoints.md}) {
    padding: 5px 15px;
  }
`;

const WrapperBanner = styled('div')`
  max-width: 1244px;
  margin: 0 auto;
  padding: 5px 10px;
  .gatsby-image-wrapper {
    border-radius: 30px;
  }
  .mobile-view { display: none; }
  @media (max-width: ${breakpoints.sm}) {
    padding: 5px 15px;
    .mobile-view { display: block; }
    .desktop-view { display: none; }
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
  }
`;

const HomePage = ({ data, search, navigate }) => {
  const homePageNode = data.allWordpressPage.edges[0].node;
  const metaTitle =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_title
      ? homePageNode.yoast_meta.yoast_wpseo_title
      : homePageNode.title + process.env.OWNER;
  const metaDesc =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_metadesc
      ? homePageNode.yoast_meta.yoast_wpseo_metadesc
      : '';
  const slider = homePageNode.acf.top_slider;
  const sliderMobile = homePageNode.acf.top_slider_mobile;
  const showSlider = slider && slider.length > 0;
  const slide1Desktop = slider[0];
  const showSliderMobile = sliderMobile && sliderMobile.length > 0;
  const slide1Mobile = sliderMobile[0];
  const showPlayForFree = homePageNode.acf.play_for_free;
  const showDemoGames = homePageNode.acf.demo_games_10.length > 0;
  const showPopularGames = homePageNode.acf.popular_games.length > 0;
  const showGameProviders = homePageNode.acf.game_providers.length > 0;
  const socialImage = homePageNode.featured_media?.localFile?.publicURL;

  const handleMoreClick = e => {
    e.preventDefault();
    const target = document.getElementById('home-text-content');
    target.classList.toggle('full-height');
    const self = document.getElementById('home-more-less');
    self.classList.toggle('show-less');
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        showSchema={true}
        keywords="GCLUB Casino, คาสิโนออนไลน์, เว็บพนันออนไลน์, บาคาร่าออนไลน์, สล็อตออนไลน์, สมัคร GCLUB, ทางเข้า GCLUB"
        socialImage={socialImage}
      />
      <Parallax />
      <div id="home-content-all" className="with-overflow-slider">
        {showSlider &&
        <WrapperBanner>
          <div className="desktop-view">
            <SlickSlide
              key={slide1Desktop.path}
              img={slide1Desktop.localFile}
              altText={slide1Desktop.alt_text}
              line1={slide1Desktop.acf ? slide1Desktop.acf.slide_title : ''}
              line2={slide1Desktop.acf ? slide1Desktop.acf.slide_description : ''}
              link={slide1Desktop.acf ? slide1Desktop.acf.slide_url : ''}
              ext={slide1Desktop.acf && slide1Desktop.acf.slide_url.indexOf('http') !== -1}
              anchor={slide1Desktop.acf && slide1Desktop.acf.slide_url.indexOf('#') !== -1}
            />
          </div>
          {showSliderMobile &&
          <div className="mobile-view">
            <SlickSlide
              key={slide1Mobile.path}
              img={slide1Mobile.localFile}
              altText={slide1Mobile.alt_text}
              line1={slide1Mobile.acf ? slide1Mobile.acf.slide_title : ''}
              line2={slide1Mobile.acf ? slide1Mobile.acf.slide_description : ''}
              link={slide1Mobile.acf ? slide1Mobile.acf.slide_url : ''}
              ext={slide1Mobile.acf && slide1Mobile.acf.slide_url.indexOf('http') !== -1}
              anchor={slide1Mobile.acf && slide1Mobile.acf.slide_url.indexOf('#') !== -1}
            />
          </div>
          }
        </WrapperBanner>
        }

        <WrapperPadded>
          <Steps />
        </WrapperPadded>

        <WrapperDesktop>
          {showPlayForFree && <PlayForFree />}

          {showDemoGames && (
            <LazyLoad once={true} offset={100}>
              <Demo10Games duration={300000} />
            </LazyLoad>
          )}

          {showPopularGames && <PopularGames />}
        </WrapperDesktop>
        <CardsWrapper>
          <ChooseGameImg />
          <span>CHOOSE YOUR WAY TO PLAY</span>
        </CardsWrapper>
        {showGameProviders && (
          <LazyLoad once={true}>
            <MobileGameProvider />
            <GameProviders />
          </LazyLoad>
        )}
        <WrapperPadded>
          {homePageNode.content && (
            <ContentWrapper>
              <div id="home-text-content" className="home-text-content" dangerouslySetInnerHTML={{ __html: homePageNode.content }} />
              <a
                id="home-more-less"
                className="button transparent-button show-more"
                href="#home-text-content"
                onClick={e => handleMoreClick(e)}
              >
                <span className="more-span">อ่านเพิ่มเติม</span>
                <span className="less-span">แสดงน้อยลง</span>
              </a>
            </ContentWrapper>
          )}
          <RecentPosts />
        </WrapperPadded>

        <WrapperPadded>
          <WithdrawCommentsStaticLoadmore />
        </WrapperPadded>
      </div>
    </Layout>
  );
};

export default withLocation(HomePage);

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          title
          content
          wordpress_id
          comment_status
          featured_media {
            alt_text
            localFile {
            publicURL
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
          acf {
            top_slider {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1225) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            top_slider_mobile {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 710) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }

            demo_games_10 {
              id
            }

            game_providers {
              alt_text
            }
            play_for_free {
              alt_text
              title
              path
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }

            popular_games {
              id
            }
          }
        }
      }
    }

    allWordpressGclubcasinoGameslist {
      edges {
        node {
          id
          _id
          code
          name
          thumb {
            source_url
            alt_text
          }
        }
      }
    }
  }
`;
