import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { breakpoints, color } from '../../utils/style';

const SlideWrap = styled('div')`
  position: relative;
  text-align: center;
  .slide-div {
    display: block;
  }
  a:hover, a:active {
    .p {
      color: ${color.white};
    }
  }
`;

const TextBlock = styled('div')`
  width:100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100%;
  overflow: hidden;
  .desktop-wrapper {
    max-width: 1150px;
    padding: 0 25px;
    margin: 0 auto;
    text-align: left;
  }
  .h1 {
    margin-bottom: 4px;
    font-size: 52px;
    font-weight: bold;
    color: ${color.orange};
    text-transform: uppercase;
  }
  .p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  .slide-wrap {
    display: block;
  }
  @media (max-width: ${breakpoints.lg}) {
    .h1 {
      font-size: 2rem;
      margin: 0 0 1rem;
    }
    .p {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .desktop-wrapper {
      padding: 0 20px;
    }
    .h1 {
      font-size: 1.4rem;
      margin-top: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .p {
      font-size: 0.8rem;
      height: 18px;
      overflow: hidden;
    }
    .button {
      padding: 12px 20px;
    }
  }
  
  @media (max-width: ${breakpoints.xs}) {
    top: 48%;
    .h1 {
      margin: 0;
    }
    .p {
      margin-bottom: 8px;
    }
    .button {
      padding: 10px 20px;
      height: 36px;
    }
  }
  
`;

const SlickSlide = ({ img, altText, line1, line2, link, ext, anchor } ) => {
  const { childImageSharp = {} } = img || {};
  
  return (
    <SlideWrap>
      {!link ?
      <div className="slide-div">
        <Img fluid={childImageSharp.fluid} alt={altText ? altText : 'slide'} />
        {(line1 || line2) &&
        <TextBlock className="text-block">
          <div className="desktop-wrapper">
            {line1 &&
            <div className="h1">{line1}</div>
            }
            {line2 &&
            <div className="p">{line2}</div>
            }
          </div>
        </TextBlock>
        }
      </div>
      : <a className="slide-div" href={(ext || anchor) ? link : `${process.env.URL}` + link}>
          <Img fluid={childImageSharp.fluid} alt={altText ? altText : 'slide'} />
          {(line1 || line2) &&
          <TextBlock className="text-block">
            <div className="desktop-wrapper">
              {line1 &&
              <div className="h1">{line1}</div>
              }
              {line2 &&
              <div className="p">{line2}</div>
              }
              <span className="button gradient-button">คลิกเลย</span>
            </div>
          </TextBlock>
          }
        </a>
      }
    </SlideWrap>
  )
};

SlickSlide.propTypes = {
  img: PropTypes.object.isRequired,
  altText: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  link: PropTypes.string,
  ext: PropTypes.bool,
  anchor: PropTypes.bool,
};

export default SlickSlide;