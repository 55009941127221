import React from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/style';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const GameProvidersWrap = styled('div')`
  padding: 30px 0;
  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

const GalleryImages = styled('div')`
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  z-index: -1;
  width: 1244px;
  padding: 0 10px;
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  .gallery-item {
    z-index: 1;
    padding: 10px;
    flex-basis: 14.6%;
    display: inline-block;
    vertical-align: middle;
    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 0.08);
      border-radius: 8px;
      filter: none;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .gallery-item {
      flex-basis: 24%;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    padding: 0 25px;
    .gallery-item {
      flex-basis: 45%;
    }
  }
`;
const GameProviders = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                game_providers {
                  alt_text
                  id
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 250) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  acf {
                    slide_url
                    slide_title
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <GameProvidersWrap>
        <GalleryImages>
          {data.allWordpressPage.edges[0].node.acf.game_providers.map(item => (
            <a
              className="gallery-item"
              href={
                item.acf && item.acf.slide_url.indexOf('http') !== -1
                  ? item.acf.slide_url
                  : process.env.URL + item.acf.slide_url
              }
              key={'game-provider-' + item.id}
            >
              {item && item.localFile && item.localFile.childImageSharp && (
                <Img
                  fluid={item.localFile.childImageSharp.fluid}
                  alt={item.alt_text}
                />
              )}
            </a>
          ))}
        </GalleryImages>
      </GameProvidersWrap>
    )}
  />
);
export default GameProviders;
