import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../utils/style';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Heart from '../../../icons/svg/Heart';

const PopularGamesWrap = styled('div')`
  margin: 50px 0;
  .h3 {
    margin-bottom: 2rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    margin: 50px 0 0;
    .h3 {
      margin-bottom: 0.7rem;
    }
    .button {
      margin: 30px 0 10px 10px;
    }
  }
`;

const GalleryImages = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  .gallery-item {
    margin-bottom: 2%;
    flex-basis: 32%;
    &:hover, &:active {
      text-decoration: none;
      color: ${color.white};
      border: none;
      .button-container {
        background: rgba(0,0,0,0.8);
      }
      .button {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .gallery-item {
      flex-basis: 48%;
    } 
  }
  @media (max-width: ${breakpoints.sm}) {
    padding: 0 5px;
    .gallery-item {
      flex-basis: 100%;
    } 
  }
`;

const GalleryImage = styled('div')`
  .image-container {
    position: relative;
    border-radius: 10px;
  }
  .button-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 20px;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    border-radius: 10px;
  }
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  .button {
    position: absolute;
    bottom: 50%;
    left: 50%;
    margin-left: -50px;
    margin-bottom: -20px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  .image-desc {
    padding: 0.5em 0;
    width: 100%;
    opacity: 0.8;
    font-size: 14px;
    strong { 
      margin-right: 10px;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    .image-desc {
      font-size: 0.8rem;
      margin: 10px 0 20px;
    }
  }
`;
const PopularGames = (props ) => (
  <StaticQuery
    query=
      {graphql`
        query {
          allWordpressPage(
       filter: {
         slug: {eq: "home"}
       }
      ) {
      edges {
        node {
          acf {
            popular_games {
              alt_text
              title
              id
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
        }
      `}
    render={data => (
      <PopularGamesWrap>
        <h2 className="h3 with-icon">
          <Heart/>
          <span>เกมคาสิโนออนไลน์</span>
        </h2>
        <GalleryImages>
          {data.allWordpressPage.edges[0].node.acf.popular_games.map((item) => {
              return (
                <Link className="gallery-item" to={item.acf.slide_url} key={'popular-game-slide-' + item.id}>
                  <GalleryImage>
                    <div className="image-container">
                      <Img fluid={item?.localFile?.childImageSharp?.fluid} alt={item.alt_text}/>
                      <div className="button-container">
                       <span className="button gradient-button">อ่านต่อ</span>
                      </div>
                    </div>
                    {(item.acf.slide_title || item.acf.slide_description) &&
                    <div className="wrapper-image-desc">
                      <div className="image-desc">
                        {item.acf.slide_title &&
                        <strong>
                          <span>{item.acf.slide_title}</span>
                        </strong>
                        }
                        {item.acf.slide_description &&
                        <span>{item.acf.slide_description}</span>
                        }
                      </div>
                    </div>
                    }

                  </GalleryImage>
                </Link>
              )
            }
          )}
        </GalleryImages>
      </PopularGamesWrap>
    )}
  />
);
export default PopularGames;

PopularGames.propTypes = {
  isHome: PropTypes.bool,
};
