import React from 'react';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import Step from '../../../icons/svg/Step';
import Deposit from '../../../icons/svg/Deposit';
import Register from '../../../icons/svg/Register';
import Enjoy from '../../../icons/svg/Enjoy';

const WrapperSection = styled('div')`
  margin: 30px 0;
  height: 120px;
  background: rgba(108, 17, 15, 0.3);
  border-radius: 20px;
  position: relative;
  .step-block {
    position: absolute;
    top: 0;
    height: 100%;
    &:hover,
    &:active,
    &:focus {
      color: ${color.white};
      text-decoration: none;
      text-shadow: 0 0 9px ${color.peach};
      .step-title {
        color: ${color.white};
      }
    }
  }
  .step-title {
    display: block;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: ${color.peach};
    text-shadow: 0 0 9px ${color.peach};
  }
  .step-details {
    font-size: 1rem;
  }
  .content-wrap {
    display: flex;
    align-items: center;
    svg {
      pointer-events: none;
      filter: drop-shadow(0 0 3px ${color.peach});
    }
  }
  .step-icon {
    position: absolute;
  }
  .step-icon-1 {
    display: none;
  }
  .step-icon-2,
  .step-icon-3 {
    right: 108%;
  }
  .text-block {
    position: relative;
    z-index: 1;
  }

  @media (min-width: ${breakpoints.mdMin}) {
    .step-icon-1 {
      animation: run linear infinite 5s;
      display: block;
    }
    @keyframes run {
      0% {
        left: -50%;
      }
      100% {
        left: 280%;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    position: static;
    height: auto;
    overflow: hidden;
    .step-block {
      position: relative;
      left: 0;
      top: auto;
      display: block;
      width: 100%;
    }
    .step-icon {
      right: auto;
      left: -70px;
      display: block;
    }
    .content-wrap {
      svg {
        width: 80px !important;
        margin: 0 60px 0 10px !important;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    border-radius: 10px;
    margin: 10px 0;
    .step-icon {
      left: -80px;
      svg {
        height: 66px;
      }
    }
    .content-wrap {
      height: 66px;
      svg {
        width: 50px !important;
        margin: 0 33px 0 10px !important;
      }
    }
    .step-title {
      font-size: 14px;
      margin: 5px 0 0;
    }
    .step-details {
      font-size: 13px;
      display: block;
      width: 92%;
      line-height: 1.1;
    }
  }
`;

const Step1Wrap = styled('a')`
  width: 28%;
  left: 3%;
  #e22oam6p98dz3 {
    animation: cherry3_c_o 7000ms linear infinite normal forwards;
    animation-delay: 3s;
  }

  #e22oam6p98dz4 {
    animation: cherry4_c_o 7000ms linear infinite normal forwards;
    animation-delay: 3s;
  }

  @keyframes cherry3_c_o {
    0% {
      opacity: 1
    }
    72.285714% {
      opacity: 1
    }
    73% {
      opacity: 0
    }
    73.714286% {
      opacity: 1
    }
    74.428571% {
      opacity: 0
    }
    75.142857% {
      opacity: 1
    }
    75.857143% {
      opacity: 0
    }
    76.571429% {
      opacity: 1
    }
    77.285714% {
      opacity: 0
    }
    78.142857% {
      opacity: 1
    }
    81.428571% {
      opacity: 1
    }
    100% {
      opacity: 1
    }
  }

  @keyframes cherry4_c_o {
    0% {
      opacity: 1
    }
    72.285714% {
      opacity: 1
    }
    73% {
      opacity: 0
    }
    73.714286% {
      opacity: 1
    }
    74.428571% {
      opacity: 0
    }
    75.142857% {
      opacity: 1
    }
    75.857143% {
      opacity: 0
    }
    76.571429% {
      opacity: 1
    }
    77.285714% {
      opacity: 0
    }
    78.142857% {
      opacity: 1
    }
    100% {
      opacity: 1
    }
  }

  .content-wrap {
    svg {
      margin: 0 5px 0 -15px;
    }
  }
`;

const Step2Wrap = styled('a')`
  width: 30%;
  top: 10px;
  left: 35%;
  padding: 0 3%;
  .content-wrap {
    svg {
      margin: 0 10px 0 -50px;
    }
  }
  #eb83x3mhelm6 {
    animation: eb83x3mhelm6_c_o 7000ms linear infinite normal forwards;
    animation-delay: 3s;
  }

  @keyframes eb83x3mhelm6_c_o {
    0% {
      opacity: 1
    }
    83.714286% {
      opacity: 1
    }
    84.428571% {
      opacity: 0
    }
    85.142857% {
      opacity: 1
    }
    85.857143% {
      opacity: 0
    }
    86.571429% {
      opacity: 1
    }
    87.285714% {
      opacity: 0
    }
    88% {
      opacity: 1
    }
    88.714286% {
      opacity: 0
    }
    89.571429% {
      opacity: 1
    }
    100% {
      opacity: 1
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    .content-wrap {
      svg {
        width: 60px !important;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .content-wrap {
      svg {
        width: 60px !important;
      }
    }
  }
`;

const Step3Wrap = styled('a')`
  width: 28%;
  top: 10px;
  left: 69%;
  .content-wrap {
    svg {
      margin: 0 10px 0 -10px;
    }
  }
  #ennqfnopjtec6 {
    animation: ennqfnopjtec6_c_o 7000ms linear infinite normal forwards;
    animation-delay: 3s;
  }

  @keyframes ennqfnopjtec6_c_o {
    0% {
      opacity: 1
    }
    92.857143% {
      opacity: 1
    }
    93.571429% {
      opacity: 0
    }
    94.285714% {
      opacity: 1
    }
    95% {
      opacity: 0
    }
    95.714286% {
      opacity: 1
    }
    96.428571% {
      opacity: 0
    }
    97.142857% {
      opacity: 1
    }
    97.857143% {
      opacity: 0
    }
    98.714286% {
      opacity: 1
    }
    100% {
      opacity: 1
    }
  }

`;

const Steps = () => (
  <WrapperSection>
    <Step1Wrap className="step-block" href={process.env.LINE_ID}>
      <div className="step-icon step-icon-1">
        <Step id="step-icon-1" />
      </div>

      <div className="content-wrap">
        <Register width={104} height={120} />

        <div className="text-block">
          <span className="step-title">1. สมัครสมาชิก GCLUB</span>
          <span className="step-details">
            แอดไลน์ LINE{process.env.LINE_ID_TEXT} หรือ Tel. {process.env.PHONE_TO_CALL}
          </span>
        </div>
      </div>
    </Step1Wrap>
    <Step2Wrap className="step-block" href={process.env.URL + '/banking/'}>
      <div className="step-icon step-icon-2">
        <Step id="step-icon-2" />
      </div>
      <div className="content-wrap">
        <Deposit width={130} height={120} />
        <div className="text-block">
          <span className="step-title">2. ฝากเงิน</span>
          <span className="step-details">
            ติกต่อฝ่าย Call Center เพื่อรับรายละเอียดช่องทางการฝากเงิน
          </span>
        </div>
      </div>
    </Step2Wrap>
    <Step3Wrap
      className="step-block no-desktop-arrow"
      href={process.env.URL + '/gclub-download/'}
    >
      <div className="step-icon step-icon-3">
        <Step id="step-icon-3" />
      </div>
      <div className="content-wrap no-desktop-arrow" id="step3-id">
        <Enjoy width={130} height={120} />
        <div className="text-block">
          <span className="step-title">3. สนุกกับเกม</span>
          <span className="step-details">
            รับ Username และ Password พร้อมเล่นเกมได้เลย
          </span>
        </div>
      </div>
    </Step3Wrap>
  </WrapperSection>
);

export default Steps;
