import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../utils/style';
import PopupWindow, { invokePopup } from '../shared/PopupWindow';
import IcomoonIcon from '../shared/IcomoonIcon';

const AnnouncementWrapper = styled('div')`
  background: ${color.black};
  &.hidden {
    display: none;
  }
  .h3, h4 {
    color: ${color.orange};
  }
  
`;

const DesktopWrapper = styled('div')`
  max-width: 1244px;
  margin: 0 auto;
  padding: 10px 150px 10px 10px;
  font-size: 13px;
  position: relative;
  .button-block {
    position: absolute;
    top: 7px;
    right: 10px;
  }
  .close-box, .show-all {
    background: none;
    border: none;
    color: ${color.white};
    font-size: 13px;
    cursor: pointer;
    &:hover, &:active, &:focus {
      outline: none;
      opacity: 0.7;
    }
    span {
      text-decoration: underline;
      font-family: 'Kanit','Arial', sans-serif;
    }
  }
  .close-box {
    span {
      display: inline-block;
      width: 1px;
      text-indent: -9999px;
    }
  }
  .orange {
    margin-right: 10px;
  }
  @media (max-width: ${breakpoints.sm}) {
    font-size: 12px;
    padding: 10px 85px 10px 15px;
    .text-wrapper {
      max-height: 3.2rem;
      overflow: hidden;
    }
    .button-block {
      position: static;
    }
    .close-box {
      position: absolute;
      right: 10px;
      top: 5px;
    }
    .show-all {
      position: absolute;
      bottom: 5px;
      right: 10px;
    }
  }
`;

const hideAnnouncementBar = () => {
  const announcementBar = document.getElementById('announcement-bar');
  announcementBar.classList.toggle('hidden');
};



const Announcements = () => {
  return(
  <StaticQuery
    query=
      {graphql`
        query {
          home: allWordpressPage(filter: {slug: {eq: "home"}}) {
            edges {
              node {
                acf {
                  announcement_list
                  announcement_top
                }
              }
            }
          }
        }
      `}
    render={data => (
      data.home.edges[0].node.acf &&
          (data.home.edges[0].node.acf.announcement_top || data.home.edges[0].node.acf.announcement_top) ?

      <AnnouncementWrapper id="announcement-bar">
        {data.home.edges[0].node.acf && data.home.edges[0].node.acf.announcement_top &&
          <DesktopWrapper>
            <div className="text-wrapper">
              <span className="orange">ประกาศ:</span>
              <span dangerouslySetInnerHTML={{ __html: data.home.edges[0].node.acf.announcement_top }}/>
            </div>
            <div className="button-block">
              {data.home.edges[0].node.acf && data.home.edges[0].node.acf.announcement_list &&
              <button className="show-all" onClick={(e) => invokePopup(e, 'announcement-popup-window')}>
                <span>ดูทั้งหมด</span>
              </button>
              }
              <button className="close-box" onClick={hideAnnouncementBar}>
                <IcomoonIcon icon="close" color={color.white} size={22}/>
                <span>Close</span>
              </button>
            </div>
          </DesktopWrapper>
        }
        {data.home.edges[0].node.acf && data.home.edges[0].node.acf.announcement_list &&
          <PopupWindow title="ประกาศจาก Gclub Casino official site" popupId="announcement-popup-window" extraClass="wide">
            <div className="accouncement-list">
              <div dangerouslySetInnerHTML={{ __html: data.home.edges[0].node.acf.announcement_list }}/>
            </div>
          </PopupWindow>
        }

      </AnnouncementWrapper> : null
    )}
  />
)};


export default Announcements;