import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../utils/style';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import IcomoonIcon from '../../shared/IcomoonIcon';
import PopupWindow, { invokePopup } from '../../shared/PopupIframe';
import LoginFormPopup from '../../Auth/Form/LoginFormPopup';
import ResetLinkFormPopup  from '../../Auth/Form/ResetLinkFormPopup';
//import RegistrationForm from '../../Auth/Form/RegistrationForm';
import StoreContext from '../../shared/Context/StoreContext';
import StarsHome from './StarsHome';

const PlayForFreeWrap = styled('div')`
  .title-block {
    position: relative;
    margin: 9rem 0 1rem;
  }
  .h1 {
    margin: 0 auto 40px;
    text-align: center;
  }
  @media (max-width: ${breakpoints.md}) {
    .title-block {
      margin: 3.5rem 0 1rem;
    }
  }  
  @media (max-width: ${breakpoints.sm}) {
    .h1 {
      margin-bottom: 0.7rem;
    }
  }
`;

const PlayForFreeAll = styled('div')`
  clear: both;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const PlayForFreeVideo = styled('div')`
  flex-basis: 32%;
  background: ${color.darkRed};
  height: 98.5%;
  position: relative;
  border-radius: 10px;
  .play-button {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin: -45px 0 0 -55px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    span {
      display: inline-block;
      width: 1px;
      text-indent: -9999px;
    }
    &:hover, &:active {
      svg {
        path {fill: ${color.orange} !important;}
      }
    }
  }
  .terms-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 60px 0 0 -50px;
    width: 100px;
    z-index: 1;
    visibility: hidden;
  }
  img {
    border-radius: 15px;
  }
  &:hover, &:active {
    .terms-button, .play-button {
      visibility: visible;
    }
    img {
      filter: brightness(20%);
    }
  }
  @media (max-width: ${breakpoints.md}) {
   
    .terms-button, .play-button {
      visibility: visible;
    }
    .play-button {
      top: 38%;
    }
    .terms-button {
      top: 40%;
    }
    img {
      filter: brightness(20%);
    }
  }
  @media (max-width: ${breakpoints.sm}) {
     flex-basis: 100%;
     margin: 0 5px;
   }
`;

const GameAds = styled('div')`
   flex-basis: 68%;
   text-align: right;
   @media (max-width: ${breakpoints.sm}) {
     margin: 12px 5px 0;
     flex-basis: 100%;
   }
`;

const GameAd = styled('div')`
  width: 30%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 3.2% 3.3%;
  position: relative;
  .game-title {
    width: 100%;
    padding: 10px;
    text-align: center;
    display: none;
    position: absolute;
    top: 1%;
    left: 0;
    z-index: 1;
    color: ${color.white};
  }
  .show-more, .coming-soon {
    width: 120px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left:50%;
    margin: -25px 0 0 -60px;
    display: none;
  }
  .coming-soon {
    background: transparent;
    color: ${color.white};
    padding: 12px;
    text-align: center;
    pointer-events: none;
    width: 150px;
    margin: -25px 0 0 -75px;
  }
  .ribbon {
    position: absolute;
    top: 12%;
    right: -5px;
    z-index: 1;
    padding: 5px 10px;
    background: ${color.orange};
    color: ${color.midRed};
    font-weight: bold;
    border-radius: 4px;
  }
  &:hover, &:active {
    .ribbon {
      display: none;
    }
    .coming-soon, .show-more, .game-title {
      display: inline-block;
    }
  }
  a, .a {
    display: block;
    &:hover, &:active {
      img {
        filter: brightness(20%);
      }
    }
  }
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  @media (max-width: ${breakpoints.sm}) {
    width: calc(50% - 12px);
    margin: 12px 0;
    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 12px;
    }
    .coming-soon {
      font-size: 0.8rem;
    }
  }
  
`;

const PlayForFree = () => {
  const storeValue = useContext(StoreContext);
  const username = storeValue.username;

  const [selectedGameUrl, setSelectedGameUrl] = useState('');
  const setGameUrlState = (url) => setSelectedGameUrl(prevState => url);

  const gameClick = (e, slide_title, game_title, slide_description, slide_url) => {
    if (!username) {
      e.preventDefault();
      invokePopup(e, 'login-popup-window');
    }
    setGameUrlState(slide_url);
    if (slide_description) {
      return false;
    }
  };

  const videoClick = (e, iframeSrc) => {
    const homeVideoIframe = document.getElementById('free-spin-home-iframe');
    homeVideoIframe.src = iframeSrc;
    invokePopup(e, "free-spin-home-popup");
  };

  return(
    <StaticQuery
      query=
        {graphql`
        query {
          allWordpressPage(
       filter: {
         slug: {eq: "home"}
       }
      ) {
      edges {
        node {
          acf {
            play_for_free_title
            play_for_free_video_file {
              source_url
            }
            play_for_free_video_cover {
              alt_text
              title
              path
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            play_for_free {
              alt_text
              title
              path
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
        }
      `}
      render={data => (
        <PlayForFreeWrap id="home-play-for-free" className="home-play-for-free">
          <div className="title-block">
            <h1 className="h1 rich-design a-center">
              <span>{data.allWordpressPage.edges[0].node.acf.play_for_free_title}</span>
            </h1>
            <StarsHome/>
          </div>
          <PlayForFreeAll>
            <PlayForFreeVideo>
              {data?.allWordpressPage?.edges[0]?.node?.acf?.play_for_free_video_file?.source_url &&
              <button className="play-button"
                      onClick={(e) => videoClick(e, data.allWordpressPage.edges[0].node.acf.play_for_free_video_file.source_url)}>
                <IcomoonIcon icon="play-button" color={color.white} size={90}/>
                <span>Play</span>
              </button>
              }
              <a href={process.env.FREESPIN_TERMS_URL} className="button transparent-button terms-button">กติกา</a>
              {data.allWordpressPage.edges[0].node.acf.play_for_free_video_cover &&
              data.allWordpressPage.edges[0].node.acf.play_for_free_video_cover.alt_text &&
              data.allWordpressPage.edges[0].node.acf.play_for_free_video_cover.localFile &&
              data.allWordpressPage.edges[0].node.acf.play_for_free_video_cover.localFile.childImageSharp &&
              <Img fluid={data.allWordpressPage.edges[0].node.acf.play_for_free_video_cover.localFile.childImageSharp.fluid}
                   alt={data.allWordpressPage.edges[0].node.acf.play_for_free_video_cover.alt_text}/>
              }
            </PlayForFreeVideo>
            <GameAds>
              {data.allWordpressPage.edges[0].node.acf.play_for_free.map((item) => {
                const localFile = item.localFile || {};
                return (
                  <GameAd key={item.path}>
                    {item.acf.slide_title &&
                    <span className="ribbon">{item.acf.slide_title}</span>
                    }
                    {item.acf.slide_description &&
                    <span className="coming-soon">{item.acf.slide_description}</span>
                    }
                    {item.acf.slide_url ?
                      <a onClick={(e)=>{gameClick(e, item.title, item.alt_text, item.acf.slide_description, item.acf.slide_url)}}
                         href={item.acf.slide_url} title={item.alt_text}>
                        {item.alt_text &&
                          <span className="game-title">{item.alt_text}</span>
                        }
                        {!item.acf.slide_description &&
                          <span className="button gradient-button show-more">เล่นเลย</span>
                        }
                        {localFile.childImageSharp && localFile.childImageSharp && item.alt_text &&
                        <Img fluid={localFile.childImageSharp.fluid} alt={item.alt_text}/>
                        }
                      </a> :
                      <div className="a">
                        {localFile.childImageSharp && localFile.childImageSharp && item.alt_text &&
                        <Img fluid={localFile.childImageSharp.fluid} alt={item.alt_text}/>
                        }
                      </div>
                    }

                  </GameAd>
                )

              })}
            </GameAds>
            {data?.allWordpressPage?.edges[0]?.node?.acf?.play_for_free_video_file?.source_url &&
            <PopupWindow title="Free Spin" popupId="free-spin-home-popup" extraClass="wide">
              <div className="iframe-container">
                <iframe
                  id="free-spin-home-iframe"
                  title="Free Spin"
                  src="about:blank"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                  allowFullScreen={true}
                  frameBorder={0}
                />
              </div>
            </PopupWindow>
            }
            {(!username) &&
              <div>
                <LoginFormPopup popupId="login-popup-window" redirectUrl={selectedGameUrl}/>
                <ResetLinkFormPopup popupId="reset-link-popup" />
                {/*<RegistrationForm popupId="home-registration-popup" />*/}
              </div>
            }
          </PlayForFreeAll>
        </PlayForFreeWrap>
      )}
    />
  )};


export default PlayForFree;