import React from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import { breakpoints, color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon'
import Img from 'gatsby-image';


const GameProviderBlock = styled('div')`
  margin: auto;
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 40px;
  width: 94%;
  height: 167px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${color.transparentWhite1};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  .arrow-icon-block{
    position: relative;
    left: -40%;
    top: 20%;
  }
  .orange-play-now-btn {
    width: 96%;
    height: 48px;
    border-radius: 24px;
    border: 1px solid ${color.veryDarkOrange};
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Kanit;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color.veryDarkOrange};
  }
  @media (min-width: ${breakpoints.smMin}) {
    display: none;
  }
`;
const SelectGameBlock = styled('div')`
  background-color: #202020;
  border-radius: 8px;
  width: 100%;
  height: 54px;
  .gatsby-image-wrapper{
    max-width: 204px;
    min-width:126px;
    width:30%;
    max-height: 48px;
    object-fit: scale-down;
    height: auto;
    margin-left: 35%;
  }
  margin-top: -26px;
  .support-image{
    min-height:54px;
    display:flex;
    align-items: center;
    img {
      float: left;
      max-height: 48px;
      object-fit: scale-down;
      height: auto;
       min-width: 126px;
      max-width: 204px;
      width: 30%;
    }
  }
  #options-menu {
    border-radius: 6px;
    left: 0;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #202020;
    z-index: 1;
    position: relative;
    .divider {
      border-radius: 6px;
      width: 90%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.24);
      margin-left: 5%;
    }
    div:not(.divider) {
      margin-left: 35%;
      max-height: 55px;
      min-width: 126px;
      width: 30%;
      img {
        max-height: 55px;
        min-height: 55px;
        /* max-width: 172px; */
        /* width: 30%; */
        object-fit: scale-down;
      }
    }
  }
`;
const openSelect = data => {
  let chooser = document.getElementById('select-game-dropdown');
  if (chooser.children.length > 1)
    while (chooser.children.length > 1) chooser.removeChild(chooser.lastChild);
  else {
    let dropDownMenu = document.createElement('div');
    dropDownMenu.id = 'options-menu';
    data.map((item, index) => {
      let image = document.createElement('img');
      image.src = item.localFile.childImageSharp.fluid.src;
      image.alt = index;
      image.addEventListener('click', () => {
        changeSelectValue(data);
      });
      let hr = document.createElement('div');
      hr.classList.add('divider');
      let blockT = document.createElement('div');
      blockT.appendChild(image);
      dropDownMenu.appendChild(hr);
      dropDownMenu.appendChild(blockT);
    });
    chooser.appendChild(dropDownMenu);
  }
};
const changeSelectValue = data => {
  console.log(event.target);
  let chooser = document.getElementById('select-game-dropdown');
  chooser.removeChild(chooser.lastChild);
  while (chooser.lastChild) chooser.removeChild(chooser.lastChild);
  let supDiv = document.createElement('div');
  supDiv.classList.add('support-image');
  supDiv.appendChild(event.target);
  chooser.appendChild(supDiv);
  event.target.addEventListener('click', () => {
    openSelect(data);
  });
  let button = document.getElementById('orange-play-now-btn');

  button.href = data[event.target.alt].acf.slide_url;
};
const MobileGameProvider = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                game_providers {
                  alt_text
                  id
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 250) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  acf {
                    slide_url
                    slide_title
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <GameProviderBlock>
          <div className='arrow-icon-block'>
            <IcomoonIcon icon="arrow-down-simple" color={color.transparentWhite3} size={24} />
          </div>
          <SelectGameBlock id="select-game-dropdown" >
            <div className='support-image' onClick={() =>
              openSelect(
                data.allWordpressPage.edges[0].node.acf.game_providers
              )
            }>
              <Img
                onClick={() =>
                  openSelect(
                    data.allWordpressPage.edges[0].node.acf.game_providers
                  )
                }
                fluid={
                  data.allWordpressPage.edges[0].node.acf.game_providers[0]
                    .localFile.childImageSharp.fluid
                }
              />
            </div>
          </SelectGameBlock>
          <a
            href={
              data.allWordpressPage.edges[0].node.acf.game_providers[0].acf
                .slide_url
            }
            id="orange-play-now-btn" className="orange-play-now-btn"
          >
            สมัครสมาชิก
          </a>
        </GameProviderBlock>
      </>
    )}
  />
);
export default MobileGameProvider;
